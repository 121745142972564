import React from 'react'
import Router from './services/Router'
import './App.scss'
import 'animate.css/animate.min.css'

function App() {
  return <Router />
}

export default App
