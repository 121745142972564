import React, { useEffect, useRef } from 'react'
import './Intro.scss'
import useWindowScroll from '../../../hooks/useWindowScroll'

const Intro = () => {
  const { scrollY } = useWindowScroll()
  const bgRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    var scaleVal = 1 + scrollY / 2000
    if (bgRef.current?.style !== undefined)
      bgRef.current.style.transform = `scale(${Math.min(scaleVal, 1.5)})`
  }, [scrollY])

  return (
    <section id="intro-wrapper">
      <div className="background" ref={bgRef}></div>

      <div className="content-container d-flex align-items-center justify-content-center">
        <div className="text-center intro-text">
          <img
            src="/resources/logo_white.png"
            alt="logo_white"
            className="intro-logo animate__animated animate__fadeIn animate__slow"
          />
          <div className="text-paragraph mt-5 animate__animated animate__fadeIn animate__slow">
            The best mobile games are those that are created with love and care,
            and that's exactly what we do every day at Arcane Blast
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
