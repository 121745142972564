import React, { useEffect, useRef, useState } from 'react'
import './AboutUs.scss'
import WAVES from 'vanta/dist/vanta.waves.min'
import Animate from '../../../components/Animation/Animate'

const AboutUs = () => {
  const [vantaEffect, setVantaEffect] = useState<any>()
  const bgRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVES({
          el: bgRef.current,
          color: 0xb0914,
          waveHeight: 40,
          shininess: 10,
          waveSpeed: 0.2,
          zoom: 1.5,
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <section id="about-us-wrapper" ref={bgRef}>
      <div className="content-container pt-6 pb-3 d-flex justify-content-center align-items-center flex-column">
        <div className="about-us-section">
          <div className="about-us-section-text text-left">
            <div className="d-flex justify-content-start ps-0 ps-lg-5">
              <div className="title-container border-bottom pb-4 ps-6 pe-6">
                <h2 className="text-white text-center">Who are we?</h2>
              </div>
            </div>
            <div className="text-paragraph mt-3 text-start">
              <Animate>
                Welcome to our indie mobile game studio, born from the hearts of
                two friends who share a passion and love for games, fantasy, and
                everything that does with it. With over 14 years of combined
                professional game development experience, we strive to bring to
                life unforgettable and immersive gaming experiences that take
                players on a journey beyond the screen.
              </Animate>
              <br />
              <Animate>
                As passionate gamers ourselves, we understand the importance of
                engaging gameplay mechanics, stunning visuals, and captivating
                stories. We believe that every game has a story to tell, and we
                pour our hearts and souls into every aspect of game development,
                from ideation to release. With a focus on player experience, we
                strive to create games that are not only fun to play, but also
                bring people together.
              </Animate>
            </div>
          </div>
          <Animate>
            <div
              className="about-us-section-image"
              style={{
                backgroundImage: `url(/resources/about_us/idle_archer_promo.png)`,
              }}
            />
          </Animate>
        </div>
        <div className="about-us-section">
          <div className="about-us-section-image d-none d-lg-block" />
          <div className="about-us-section-text text-right">
            <div className="d-flex justify-content-end pe-0 pe-lg-5">
              <div className="title-container border-bottom pb-4 ps-6 pe-6">
                <h2 className="text-white text-center">
                  Arcane Blast & Neon Play
                </h2>
              </div>
            </div>
            <Animate>
              <div className="text-paragraph mt-3 text-end">
                We are proud to have formed a powerful partnership with Neon
                Play, a renowned UK-based publishing studio known for its
                expertise in mobile gaming. With their expert services and
                support, we have successfully brought our top games to the hands
                of millions of players worldwide, expanding our reach and impact
                in the gaming community.
              </div>
            </Animate>
          </div>
        </div>
        <div className="about-us-section-big">
          <div className="row w-100 g-0">
            <Animate>
              <div
                className="col col-7 about-us-section-image-big"
                style={{
                  backgroundImage: `url(/resources/about_us/ab_and_np.jpg)`,
                }}
              />
            </Animate>
            <Animate>
              <div className="col col-9 col-xl-7 np-quote-text">
                "<b>Neon Play</b> is loving working closely with the team at{' '}
                <b>Arcane Blast</b>. Their first game <b>Idle Archer</b> has
                been a big hit and we can't wait to see the next games they
                create as we work together to find their next awesome game".
                <div className="np-quote">— Oli Christie, CEO, Neon Play</div>
              </div>
            </Animate>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
