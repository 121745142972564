import React, { ReactNode } from 'react'
import Footer from '../components/Footer/Footer'
import TopMenu from '../components/TopMenu/TopMenu'
import './MainLayout.scss'

type Props = {
  children?: ReactNode
}

const MainLayout = ({ children }: Props) => {
  return (
    <>
      <main id="main-layout-wrapper">
        <TopMenu />
        {children}
        <Footer />
      </main>
    </>
  )
}

export default MainLayout
