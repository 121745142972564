import React from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import OurGame from '../../types/OurGame'
import Animate from '../Animation/Animate'
import './OurGameItem.scss'

type Props = {
  game: OurGame
  align: 'left' | 'right'
}

const OurGameItem = ({ game, align }: Props) => {
  const { isMobile } = useWindowSize()

  return !isMobile ? (
    <Animate>
      <div
        id="our-game-item-wrapper"
        className="max-width d-flex flex-column align-items-center"
      >
        <img src={game.imageUrl} alt={game.title} className="game-image" />
        <div className="text-paragraph text-center text-white pt-2">
          {game.title}
        </div>
        {game.appStorePath && (
          <img
            role="button"
            onClick={() => window.open(game.appStorePath)}
            src="/resources/our_games/app_store.png"
            alt="appstore"
            className="app-store-image pt-2"
          />
        )}
        {game.googlePlayPath && (
          <img
            role="button"
            onClick={() => window.open(game.googlePlayPath)}
            src="/resources/our_games/google_play.png"
            alt="googleplay"
            className="app-store-image pt-2"
          />
        )}
      </div>
    </Animate>
  ) : (
    <Animate>
      <div id="our-game-item-wrapper">
        <div className="text-paragraph text-center text-white pb-3">
          {game.title}
        </div>
        <div className="d-flex justify-content-between">
          {align === 'right' && (
            <div className="me-5">
              {game.appStorePath && (
                <img
                  role="button"
                  onClick={() => window.open(game.appStorePath)}
                  src="/resources/our_games/app_store.png"
                  alt="appstore"
                  className="app-store-image"
                />
              )}
              {game.googlePlayPath && (
                <img
                  role="button"
                  onClick={() => window.open(game.googlePlayPath)}
                  src="/resources/our_games/google_play.png"
                  alt="googleplay"
                  className="app-store-image pt-2"
                />
              )}
            </div>
          )}
          <div
            className="game-image"
            style={{ backgroundImage: `url(${game.imageUrl})` }}
          />
          {align === 'left' && (
            <div className="ms-5">
              {game.appStorePath && (
                <img
                  role="button"
                  onClick={() => window.open(game.appStorePath)}
                  src="/resources/our_games/app_store.png"
                  alt="appstore"
                  className="app-store-image"
                />
              )}
              {game.googlePlayPath && (
                <img
                  role="button"
                  onClick={() => window.open(game.googlePlayPath)}
                  src="/resources/our_games/google_play.png"
                  alt="googleplay"
                  className="app-store-image pt-2"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Animate>
  )
}

export default OurGameItem
