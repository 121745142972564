import React, { useEffect, useRef } from 'react'
import OurGameItem from '../../../components/OurGameItem/OurGameItem'
import OurGame from '../../../types/OurGame'
import './OurGames.scss'
import useWindowScroll from '../../../hooks/useWindowScroll'
import useWindowSize from '../../../hooks/useWindowSize'

const ourGameItems: OurGame[] = [
  {
    title: 'Idle Archer - Tower Defense',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.LuckyPotionGames.IdleArcherTowerDefense&pli=1',
    appStorePath:
      'https://apps.apple.com/us/app/idle-archer-tower-defense-rpg/id1619485045',
    imageUrl: '/resources/our_games/idle_archer.png',
  },
  {
    title: 'Air Force: Missile Escape',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.LuckyPotion.AirForceFighterMissiles',
    appStorePath: undefined,
    imageUrl: '/resources/our_games/air_force.png',
  },
  {
    title: 'Dice of Might: Magic Arena',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.ArcaneBlast.DiceofMight',
    appStorePath:
      'https://apps.apple.com/us/app/dice-of-might-magic-arena-rpg/id1671640514',
    imageUrl: '/resources/our_games/dice_might_magic.png',
  },
  {
    title: 'Tap Assassin: Ghost Creed',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.LuckyPotionGames.TapAssassinGhostCreed',
    appStorePath: undefined,
    imageUrl: '/resources/our_games/tap_assasin.png',
  },
  {
    title: 'Idle Magic - Block Breaker',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.LuckyPotion.IdleBalls',
    appStorePath:
      'https://apps.apple.com/us/app/idle-magic-block-breaker/id1626838134',
    imageUrl: '/resources/our_games/block_breaker.png',
  },
  {
    title: 'Mage Battle Royale',
    googlePlayPath:
      'https://play.google.com/store/apps/details?id=com.ArcaneBlast.MageBattleRoyale',
    appStorePath:
      'https://apps.apple.com/us/app/mage-battle-royale/id1626283813',
    imageUrl: '/resources/our_games/mage_br.png',
  },
]

const OurGames = () => {
  const { scrollY } = useWindowScroll()
  const { height } = useWindowSize()
  const bgRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const top = wrapperRef.current?.getBoundingClientRect().top || 0
    const containerHeight =
      wrapperRef.current?.getBoundingClientRect().height || 0
    var scaleVal = 1.5
    var posY = 100
    if (height >= top) {
      scaleVal = 1.5 - (height - top) / (containerHeight * 7)
      posY = 100 - ((height - top) * 100) / containerHeight
    }
    if (bgRef.current?.style !== undefined) {
      bgRef.current.style.transform = `scale(${Math.max(scaleVal, 1)})`
      bgRef.current.style.backgroundPositionY = `${posY}px`
    }
  }, [scrollY, height, wrapperRef, bgRef])

  return (
    <section id="our-games-wrapper" ref={wrapperRef}>
      <div className="background" ref={bgRef}></div>
      <div className="content-container pt-6 pb-3">
        <div className="d-flex justify-content-center">
          <div className="title-container border-bottom pb-4 ps-6 pe-6">
            <h2 className="text-white text-center">Our Games</h2>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="games-list w-100 mt-4 d-flex flex-wrap justify-content-center">
            {ourGameItems.map((game, index) => (
              <div
                key={index}
                className="game-item-container mb-5 ms-sm-5 me-sm-5"
              >
                <OurGameItem
                  game={game}
                  align={index % 2 === 0 ? 'left' : 'right'}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurGames
