import React, { useEffect, useRef } from 'react'
import Animate from '../../../components/Animation/Animate'
import './Team.scss'
import useWindowScroll from '../../../hooks/useWindowScroll'
import useWindowSize from '../../../hooks/useWindowSize'

const Team = () => {
  const { scrollY } = useWindowScroll()
  const { height } = useWindowSize()
  const bgRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const top = wrapperRef.current?.getBoundingClientRect().top || 0
    const containerHeight =
      wrapperRef.current?.getBoundingClientRect().height || 0
    var posY = 0
    if (height >= top) {
      posY = 0 - ((height - top) * 30) / containerHeight
    }
    if (bgRef.current?.style !== undefined) {
      bgRef.current.style.backgroundPositionY = `${posY}px`
    }
  }, [scrollY, height, wrapperRef, bgRef])

  return (
    <section id="team-wrapper" ref={wrapperRef}>
      <div className="background" ref={bgRef}></div>
      <div className="content-container pt-6 pb-6">
        <div className="d-flex justify-content-center">
          <div className="title-container border-bottom pb-4 ps-6 pe-6 mb-5">
            <h2 className="text-white text-center">Meet the team</h2>
          </div>
        </div>
        <Animate>
          <div className="d-flex justify-content-center">
            <div>
              <div
                className="team-image"
                style={{
                  backgroundImage: `url(/resources/about_us/dom.jpg)`,
                }}
              />
              <div className="team-name">Dominik Brablec</div>
            </div>
            <div>
              <div
                className="team-image"
                style={{
                  backgroundImage: `url(/resources/about_us/michal.jpg)`,
                }}
              />
              <div className="team-name">Michal Marsalek</div>
            </div>
          </div>
        </Animate>
      </div>
    </section>
  )
}

export default Team
