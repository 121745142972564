import React, { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import Scrollspy from 'react-scrollspy'
import { FaBars, FaTimes } from 'react-icons/fa'
import './TopMenu.scss'

const TopMenu = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  return (
    <Navbar
      id="top-menu-wrapper"
      className={`ps-2 pe-2 ps-xl-3 pe-xl-3 w-100 ${
        isFullScreen ? 'full-screen' : ''
      }`}
    >
      <div className="w-100 d-flex align-items-center justify-content-between">
        <Nav.Link href="#home">
          <Navbar.Brand>
            <img
              src="/resources/logo_white.png"
              alt="logo"
              className={`nav-logo animate__animated animate__fadeIn ${
                isFullScreen ? 'd-block' : 'd-none'
              }`}
            />
            <img
              src="/resources/logo_color.png"
              alt="logo"
              className={`nav-logo animate__animated animate__fadeIn ${
                isFullScreen ? 'd-none' : 'd-block'
              }`}
            />
          </Navbar.Brand>
        </Nav.Link>
        <Nav className="ms-auto pt-2 pb-2 d-none d-lg-block">
          <Scrollspy
            className="m-0 p-0 d-flex"
            items={['home', 'games', 'aboutus', 'team']}
            currentClassName="current"
            offset={-100}
          >
            <Nav.Link href="#home" className={`ps-2 ps-xl-4`}>
              <div>Home</div>
              <div className="activeIndicator" />
            </Nav.Link>
            <Nav.Link href="#games" className={`ps-2 ps-xl-4`}>
              <div>Games</div>
              <div className="activeIndicator" />
            </Nav.Link>
            <Nav.Link href="#aboutus" className={`ps-2 ps-xl-4`}>
              <div>About us</div>
              <div className="activeIndicator" />
            </Nav.Link>
            <Nav.Link href="#team" className={`ps-2 ps-xl-4`}>
              <div>Team</div>
              <div className="activeIndicator" />
            </Nav.Link>
          </Scrollspy>
        </Nav>
        <div className="d-lg-none">
          {isFullScreen ? (
            <FaTimes
              role="button"
              className="me-2 collapse-icon animate__animated animate__fadeIn"
              onClick={() => setIsFullScreen(false)}
            />
          ) : (
            <FaBars
              role="button"
              className="me-2 collapse-icon animate__animated animate__fadeIn"
              onClick={() => setIsFullScreen(true)}
            />
          )}
        </div>
      </div>
      {isFullScreen && (
        <div className="h-100 d-flex flex-column align-items-start justify-content-center">
          <Nav.Link href="#home" onClick={() => setIsFullScreen(false)}>
            <div className="burger-link">Home</div>
          </Nav.Link>
          <Nav.Link href="#games" onClick={() => setIsFullScreen(false)}>
            <div className="burger-link">Games</div>
          </Nav.Link>
          <Nav.Link href="#aboutus" onClick={() => setIsFullScreen(false)}>
            <div className="burger-link">About us</div>
          </Nav.Link>
          <Nav.Link href="#team" onClick={() => setIsFullScreen(false)}>
            <div className="burger-link">Team</div>
          </Nav.Link>
        </div>
      )}
    </Navbar>
  )
}

export default TopMenu
