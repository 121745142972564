import { useEffect, useState } from 'react'

const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState<number>(window.scrollY)

  const handleScrollChange = (e: any) => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChange)
    return () => window.removeEventListener('scroll', handleScrollChange)
  }, [])

  return { scrollY }
}

export default useWindowScroll
