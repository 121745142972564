import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <section
      id="footer-wrapper"
      className="d-flex justify-content-center w-100"
    >
      <div className="content-container d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-end w-100">
        <div className="footer-item mb-5 mb-lg-0">
          <h3>Location</h3>
          <div className="mt-4">
            <div className="text-small">Arcane Blast s.r.o.</div>
            <div className="text-small">Milady Horákové 110/44, Zábrdovice</div>
            <div className="text-small">602 00 Brno</div>
          </div>
        </div>
        <div className="footer-item mb-5 mb-lg-0">
          <img src="/resources/logo_white.png" alt="logo" className="logo" />
          <div className="mt-4">
            <div className="text-small">
              All content copyright © 2023 Arcane Blast s.r.o.
            </div>
          </div>
        </div>
        <div className="footer-item">
          <h3>Contact</h3>
          <div className="mt-4">
            <a className="text-small" href="mailto:main@arcaneblast.com">
              main@arcaneblast.com
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
