import React, { useEffect } from 'react'
import MainLayout from '../../layouts/MainLayout'
import AboutUs from './comps/AboutUs'
import Intro from './comps/Intro'
import OurGames from './comps/OurGames'
import Team from './comps/Team'

const Home = () => {
  useEffect(() => {
    window.scrollBy(0, 1)
  })

  return (
    <MainLayout>
      <div id="home">
        <Intro />
      </div>
      <div id="games">
        <OurGames />
      </div>
      <div id="aboutus">
        <AboutUs />
      </div>
      <div id="team">
        <Team />
      </div>
    </MainLayout>
  )
}

export default Home
