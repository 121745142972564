import React, { ReactNode } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

type Props = {
  children: ReactNode
  noDelay?: boolean
}

const Animate = ({ children, noDelay }: Props) => {
  return (
    <AnimationOnScroll
      animateIn="animate__fadeIn"
      duration={1.5}
      animateOnce
      animatePreScroll={false}
      offset={100}
      delay={noDelay ? 0 : 300}
    >
      {children}
    </AnimationOnScroll>
  )
}

export default Animate
